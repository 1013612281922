import React from "react";

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}


const Button:React.FC<ButtonProps> = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="bg-black text-white rounded-full max-w-[275px] h-11 hover:opacity-50 transition-opacity ease-in-out duration-300"
  >
    {children}
  </button>
);

export default Button;

