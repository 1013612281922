import React, { useContext } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";

import { signOut } from "firebase/auth";

import { AuthProvider, AuthContext } from './Auth';
import { firebase } from "./Services/firebase";

import LoginPage from './Pages/LoginPage';
import SignUpPage from './Pages/SignUpPage';
import ForgetPasswordPage from './Pages/ForgetPasswordPage';

import HomePage from './Pages/HomePage';
import SyncNotionPage from './Pages/SyncNotionPage';
import CreateChartPage from './Pages/CreateChartPage';

const Router = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/forget-password" element={<ForgetPasswordPage />} />

          <Route path="/" element={<RequireAuth><Layout /></RequireAuth>}>
            <Route index element={<HomePage />} />
            <Route path="sync-notion" element={<SyncNotionPage />} />
            <Route path="create" element={<CreateChartPage />} />
            <Route path="account" element={<div><h2>Account</h2></div>} />
          </Route>
          <Route path="*" element={<div><h2>404</h2></div>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default Router;

const Layout = () => {
  const { user, userData } = useContext(AuthContext);

  return (
    <div>
      <div className="bg-[#2D2D2E] h-20 px-6 py-5 flex flex-row justify-between">
        <Link to='/'><div className="bg-red-400 w-[177px] h-[40px]" /></Link>

        <Link to='/create'>+ Create Chart</Link>

        <div className="flex flex-row">
          <a href='#'>Upgrade</a>

          <div className="bg-orange-300 h-10 w-10" />

          {/* <Link to='/account'>Account</Link> */}
          <button onClick={() => {signOut(firebase.auth)}}>Logout</button>
        </div>
      </div>

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { loadingAuthState, authenticated } = useContext(AuthContext);

  let location = useLocation();

  if (!authenticated && !loadingAuthState)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}
