import * as firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBijuFCZXwt86j-sSSd_eV1-Bs0SxJicUU",
  authDomain: "charts-notion.firebaseapp.com",
  projectId: "charts-notion",
  storageBucket: "charts-notion.appspot.com",
  messagingSenderId: "763508784525",
  appId: "1:763508784525:web:5d73afed2c67f569cc91c4",
  measurementId: "G-029RHC2K7K"
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const analytics = getAnalytics(app);
export const functions = getFunctions(app)
export const firestore = getFirestore(app)
export default app;