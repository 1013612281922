import React, { useState } from 'react';

import Input from '../Components/Input';
import CreateChartBlock from '../Components/CreateChart/block';

const CreateChartPage = () => {
  const [charTitle, setChartTitle] = useState('')

  return (
    <div className="flex flex-row justify-between">
      <div className="p-5 border-r">
        <CreateChartBlock title='Chart Title'>
          <Input
            placeholder='title'
            value={charTitle}
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>) => setChartTitle(e.target.value)
            }
          />
        </CreateChartBlock>

        <hr className="border-b" />

        <CreateChartBlock title='Chart Type'>
          <div className="grid grid-cols-4 gap-2">
            <div className="w-16 h-8 rounded-sm bg-neutral-300">1</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">2</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">3</div>
            <div className="w-16 h-8 rounded-sm bg-purple-400">4</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">5</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">6</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">7</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">8</div>
            <div className="w-16 h-8 rounded-sm bg-neutral-300">9</div>
          </div>
        </CreateChartBlock>

        <hr className="border-b" />

        <CreateChartBlock title='Colors'>
          <Input
            placeholder='title'
            value={charTitle}
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>) => setChartTitle(e.target.value)
            }
          />
        </CreateChartBlock>

        <hr className="border-b" />

        <CreateChartBlock title='Settings'>
          <Input
            placeholder='title'
            value={charTitle}
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>) => setChartTitle(e.target.value)
            }
          />
        </CreateChartBlock>

        <hr className="border-b" />

        <CreateChartBlock title='Data Label Format'>
          <Input
            placeholder='title'
            value={charTitle}
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>) => setChartTitle(e.target.value)
            }
          />
        </CreateChartBlock>
      </div>

      <div>
        {charTitle}
      </div>

      <div>Right part</div>
    </div>
  )
};

export default CreateChartPage;
