import React from "react";

interface CreateChartBlockProps {
  title?: string;
  children: React.ReactNode;
}


const CreateChartBlock:React.FC<CreateChartBlockProps> = ({ title, children }) => (
  <div className="py-4">
    <p className="mb-4">{title}</p>
    {children}
</div>
);

export default CreateChartBlock;

