import React from 'react';

import AuthModal from '../Components/AuthModal';
import Button from '../Components/Button';
import Input from '../Components/Input';

import Lock from '../Assets/lock.svg';

const ForgetPasswordPage = () => {
  return (
   <AuthModal>
     <>
      <div className="flex flex-col shadow-md shadow-black/30 bg-white max-w-[355px] m-auto p-10 rounded-3xl mb-6 relative z-50">
        <div className="w-[92px] h-[92px] flex justify-center bg-[#F2F2F7] rounded-full m-auto mb-7">
          <img src={Lock} alt="Lock icon" />
        </div>

        <p className="mb-5 text-start font-semibold">Recover your password</p>

        <div className="flex flex-col mb-20">
          <Input placeholder="Password" type="password" />
        </div>

        <Button onClick={() => console.log('Connexion press')}>
          Connexion
        </Button>
      </div>
      <div className="relative z-50">
        <p className="text-sm">Don't have an account ? <a href="/signup" className="underline">Sign up</a></p>
      </div>
     </>
   </AuthModal>
  )
};

export default ForgetPasswordPage;
