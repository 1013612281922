import { doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase";

import { UserData } from '../../Interfaces/User'

export const getUserData = async (userId: string) => {
  const docRef = doc(firestore, "users", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data()
    const response: UserData = {
      notionAccessToken: data.notion_access_token,
      stripeUserId: data.stripe_user_id,
      validEmail: data.valid_email
    }
    return response
  } else {
    console.log("No such document!");
    return null
  }
}