import React, { useEffect, useState, useMemo } from "react";
import { onAuthStateChanged } from 'firebase/auth';
import { User as FirebaseUser } from '@firebase/auth-types';

import { firebase, firestore } from "./Services/firebase";

import { UserData } from './Interfaces/User'

type ContextProps = {
  user: FirebaseUser | null;
  authenticated: boolean;
  setUser: any;
  userData: UserData | null;
  setUserData: any;
  loadingAuthState: boolean;
  notionAccessToken: string | null;
};

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as FirebaseUser | null);
  const [userData, setUserData] = useState(null as UserData | null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  const getUserData = async (isConnected: boolean) => {
    if (!isConnected || !user) {
      setUserData(null)
    } else {
      const data = await firestore.getUserData(user.uid)
      setUserData(data)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebase.auth, (user: any) => {
      setUser(user);
      setLoadingAuthState(false);
    });

    return unsubscribe
  }, []);

  useEffect(() => {
    getUserData(user !== null)
  }, [user])

  return (
    <AuthContext.Provider 
      value={{
        user,
        authenticated: user !== null,
        setUser,
        userData,
        setUserData,
        loadingAuthState
      }}>
        {!loadingAuthState && children}
    </AuthContext.Provider>
  );
}