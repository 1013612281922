import React, { useEffect, useState, useContext } from 'react';
import { Client } from '@notionhq/client';
import { httpsCallable } from 'firebase/functions';

import { firebase } from '../Services/firebase';
import { AuthContext } from '../Auth';

// const notion = new Client({
//   auth: 'secret_leK84R9cmY7wOEbw2PQlXNbMn6sUy7ANNpXR7LjDpCh'
// })

const HomePage = () => {
  const { user, userData } = useContext(AuthContext);
  const [hasNotionSync] = useState(!!userData?.notionAccessToken)

  // const notion = new Client({
  //   auth: userData?.notionAccessToken || ''
  // })

  const getNotionDatabasesList = async () => {
    // const databases = await notion.databases.list({})

    //   console.log('databases :', databases)
    const getNotionDatabases = httpsCallable(firebase.functions, 'getNotionDatabases');
    getNotionDatabases()
    .then((result) => {
      console.log(result)
    })
    .catch((err) => {
      
      console.log('An error occured', err)
    })
  }

  useEffect(() => {
    // !!userData?.notionAccessToken && getNotionDatabases()
    getNotionDatabasesList()
  }, [userData?.notionAccessToken])

  return (
    <div className="flex">
      <h1>Home Page</h1>

      {
        !hasNotionSync ? (
          <a href="https://api.notion.com/v1/oauth/authorize?client_id=f969e079-5fed-421c-bca8-1e6c224af3e6&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.charts-notion.com%2Fsync-notion">
            Connect your Notion account !
          </a>
        ) : (
          <p>Your Notion account is connected</p>
        )
      }
    </div>
  )
};

export default HomePage;
