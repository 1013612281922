import React, { useEffect, useState } from 'react';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  useNavigate
} from "react-router-dom";

import AuthModal from '../Components/AuthModal';
import Button from '../Components/Button';
import Input from '../Components/Input';

import { authErrorCode } from '../Helpers/auth'

import Lock from '../Assets/lock.svg';

const SignUpPage = () => {
  const [email, setEmail] = useState('simon@charrette.club')
  const [password, setPassword] = useState('Azeazeaze/')
  const [password2, setPassword2] = useState('Azeazeaze/')
  const [errorMessage, setErrorMessage] = useState(null)
  const navigate = useNavigate();

  const handleSignUp = () => {
    const auth = getAuth();

    if (password !== password2) return;

    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate('/', { replace: true });
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode)
        setErrorMessage(authErrorCode[errorCode])
      });
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [email, password, password2])

  return (
    <AuthModal>
     <>
        <div className={`flex flex-col shadow-md shadow-black/30 bg-white max-w-[355px] m-auto p-10 ${errorMessage && 'pb-3'} rounded-3xl mb-6 relative z-50`}>
          <div className="w-[92px] h-[92px] flex justify-center bg-[#F2F2F7] rounded-full m-auto mb-7">
            <img src={Lock} alt="Lock icon" />
          </div>

          <p className="mb-5 text-start font-semibold">Create your account</p>

          <div className="flex flex-col">
            <Input placeholder="Email" type="email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} value={email} />
            <Input placeholder="Password" type="password" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} value={password} />
            <Input placeholder="Repeat password" type="password" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword2(e.target.value)} value={password2} />
          </div>

          <p className="text-[11px] text-start mb-20">
            Singning up signifies that you have read and agree to the <a href="" className="underline">Terms of Service</a> and our <a href="" className="underline">Privacy policy</a>.
          </p>

          <Button onClick={() => handleSignUp()}>
            Create !
          </Button>
          {errorMessage && (<p className="text-red-500 text-sm mt-2">{errorMessage}</p>)}
        </div>
        <div className="relative z-50">
          <p className="text-sm">You already have an account ? <a href="/login" className="underline">Sign in</a></p>
        </div>
      </>
    </AuthModal>
  )
};

export default SignUpPage;
