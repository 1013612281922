import React from "react";

interface InputProps {
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: any;
}


const Input:React.FC<InputProps> = (props) => (
  <input {...props} className="h-14 border rounded-lg px-2 my-2 focus:border-2 outline-none" />
);

export default Input;