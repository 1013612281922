import { useEffect, useContext, useState } from 'react';
import { httpsCallable } from "firebase/functions";
import { useNavigate } from 'react-router-dom';

import { firebase } from '../Services/firebase';
import { AuthContext } from '../Auth';

const SyncNotionPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(null)

  useEffect(() => {
    const notionCodeToAccessToken = (async (code: string) => {
      const getNotionAccessToken = httpsCallable(firebase.functions, 'getNotionAccessToken');
      !!user?.uid && getNotionAccessToken({ "code": code, "user_id": user.uid })
      .then((result) => {
        navigate("/");
      })
      .catch((err) => {
        setError(err)
        console.log('An error occured', err)
      })
    })

    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code")
    
    if (!!code) {
      notionCodeToAccessToken(code)
    }
  }, []);

  return (
    <div className="flex">
      <p>Synchronisation of your Notion account</p>
      {error && <p>An error occured during synchronisation of your notion account</p>}
    </div>
  )
};

export default SyncNotionPage;
