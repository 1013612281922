import React, { useEffect, useState } from 'react';

import { signInWithEmailAndPassword } from "firebase/auth";
import { firebase } from '../Services/firebase';
import {
  useNavigate
} from "react-router-dom";

import AuthModal from '../Components/AuthModal';
import Button from '../Components/Button';
import Input from '../Components/Input';

import { authErrorCode } from '../Helpers/auth'

import Lock from '../Assets/lock.svg';

const LoginPage = () => {
  const [email, setEmail] = useState('simon@charrette.club')
  const [password, setPassword] = useState('Azeazeaze/')
  const [errorMessage, setErrorMessage] = useState(null)

  const navigate = useNavigate();

  const handleLogin = async () => {
    signInWithEmailAndPassword(firebase.auth, email, password)
      .then(() => {
        navigate('/', { replace: true });
      })
      .catch((error) => {
        const errorCode = error.code;
        setErrorMessage(authErrorCode[errorCode])
      });
  }

  useEffect(() => (
    setErrorMessage(null)
  ), [email, password])

  return (
   <AuthModal>
     <>
      <div className={`flex flex-col shadow-md shadow-black/30 bg-white max-w-[355px] m-auto p-10 ${errorMessage && 'pb-3'} rounded-3xl mb-6 relative z-50`}>
        <div className="w-[92px] h-[92px] flex justify-center bg-[#F2F2F7] rounded-full m-auto mb-7">
          <img src={Lock} alt="Lock icon" />
        </div>

        <p className="mb-5 text-start font-semibold">Connect to your account</p>

        <div className="flex flex-col">
          <Input placeholder="Email" type="email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} value={email} />
          <Input placeholder="Password" type="password" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} value={password} />
        </div>

        <div className="flex justify-end mb-20">
          <a
            href="/forget-password"
            className="text-xs hover:underline"
          >
            Forget password ?
          </a>
        </div>

        <Button onClick={() => handleLogin()}>
          Connexion
        </Button>
        
        {errorMessage && (<p className="text-red-500 text-sm mt-2">{errorMessage}</p>)}
      </div>
      <div className="relative z-50">
        <p className="text-sm">Don't have an account ? <a href="/signup" className="underline">Sign up</a></p>
      </div>
     </>
   </AuthModal>
  )
};

export default LoginPage;
