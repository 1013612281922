import React from "react";

import Blob from '../../Assets/Blob.svg';

interface AuthModalProps {
  children: React.ReactNode;
}


const AuthModal:React.FC<AuthModalProps> = ({ children }) => (
  <div className="flex flex-col h-screen bg-[#FAFAFB] overflow-hidden">
    <div className="m-auto w-full relative">
      {children}
      <img src={Blob} alt="Blob" className="absolute top-1/2 right-1/4 z-10" />
      <img src={Blob} alt="Blob" className="absolute top-[-250px] left-1/4 z-10" />
    </div>
  </div>
);

export default AuthModal;

